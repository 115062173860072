<template>
  <div>
    <el-dialog
      title="上传支付凭证"
      :visible.sync="isShow"
      width="800px"
      :before-close="handleClose"
      center
    >
      <div class="content">
        <el-form :model="ruleForm" :rules="rules" label-width="120px">
          <el-form-item label="上传支付凭证" prop="name">
            <el-upload
              action="#"
              :headers="uploadHeaders"
              :accept="accept.toString()"
              :on-success="handleSuccess"
              :http-request="beforeUpload"
              :limit="5"
              v-if="ruleForm.imgList.length < 5"
            >
              <el-button>选择文件</el-button>
            </el-upload>
          </el-form-item>
        </el-form>
        <div class="img-list">
          <div
            v-for="(item, index) in ruleForm.imgList"
            :key="index"
            class="item"
          >
            <img :src="fileBaseUrl + item.url" alt="" />
            <div class="op">
              <i
                class="el-icon-view"
                @click="handlePictureCardPreview(item)"
              ></i>
              <i class="el-icon-delete" @click="removeFn(index)"></i>
            </div>
          </div>
        </div>
        <div class="tip">最多上传5张</div>
        <div class="btn-wrap">
          <el-button type="primary">提交</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="fileBaseUrl + dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import url from "@/api/url/public_url";
import { ossUploadFile } from "@/api/public";
const { baseUrl } = require("@/config");
import { ACCESS_TOKEN } from "@/store/action-types";
import Vue from "vue";
export default {
  data() {
    return {
      uploadUrl: baseUrl + url.upLoadUrl,
      fileBaseUrl: baseUrl + url.getFileUrl,
      uploadHeaders: {},
      dialogVisible: false,
      dialogImageUrl: "",
      accept: [".png", ".jpg", ".jpeg"],
      ruleForm: {
        imgList: [
          // {
          //   url: '20211117132606421-55-.jpg'
          // },
          // {
          //   url: '20211117132606421-55-.jpg'
          // },
          // {
          //   url: '20211117132606421-55-.jpg'
          // },
          // {
          //   url: '20211117132606421-55-.jpg'
          // },
          // {
          //   url: '20211117132606421-55-.jpg'
          // },
        ],
      },
      rules: {},
    };
  },
  props: {
    isShow: {
      type: Boolean,
    },
  },
  mounted() {
    console.log(url.upLoadUrl);
    this.uploadHeaders = {
      "Xjcec-Auth": Vue.ls.get(ACCESS_TOKEN),
      "client-type": "info_front",
    };
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
    handlePictureCardPreview(item) {
      this.dialogImageUrl = item.url;
      this.dialogVisible = true;
    },
    beforeUpload(file) {
      file = file.file;
      let type = this.accept.map((item) => item.split(".")[1]);
      let reg = new RegExp(`^.+\.(${type.join("|")})$`, "i");
      if (!reg.test(file.name)) {
        this.$message({
          message: `请上传${type.join("、")}格式的图片`,
          type: "error",
        });
        return false;
      }
      let otherFiles = file;
      // FormData 对象
      var formData = new FormData();
      // 文件对象
      formData.append("file", otherFiles);
      ossUploadFile(formData).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "上传成功",
            type: "success",
          });
        } else {
          this.$message(res.message);
        }
      });
    },
    handleSuccess(response, file, fileList) {
      console.log(file);
      if (file.response.code == 0) {
        this.ruleForm.imgList.push({ url: file.response.data });
      }
    },
    removeFn(index) {
      this.ruleForm.imgList.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-upload .el-button {
  margin: 0;
}
.tip {
  color: red;
  text-align: center;
  margin-top: 10px;
}
.el-button {
  margin-top: 20px;
}
.btn-wrap {
  text-align: center;
}
.img-list {
  height: 135px;
  display: flex;
  align-items: center;
  .item {
    height: 135px;
    width: 135px;
    position: relative;
    border: 1px solid #cecece;
    flex-shrink: 0;
    & + .item {
      margin-left: 20px;
    }
    img {
      max-width: 100%;
      max-height: 100%;
      display: block;
    }
    .op {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: none;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.2);
      i {
        font-size: 20px;
        cursor: pointer;
        color: white;
        & + i {
          margin-left: 10px;
        }
      }
    }
    &:hover .op {
      display: flex;
    }
  }
}
</style>